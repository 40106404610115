<template>
    <div class="editor">
        <div class="head">
            <div class="title">{{ title }}</div>
        </div>
        <div class="tabs">
            <div
                class="tab"
                v-for="tab in tabs"
                :key="tab.key"
                :class="{current : tab.key === currentTab}"
                @click="changeTab(tab.key)"
            >
                {{ tab.label }}
            </div>
        </div>
        <div class="body">
            <div class="info" v-if="currentTab === 'info'">
                <div class="fields">
                    <div class="field">
                        <input-box
                            v-model="data['fio']"
                            label="ФИО"
                            :error="checkError('fio')"
                        ></input-box>
                    </div>
                    <div class="field">
                        <input-box
                            v-model="data['phone']"
                            type="phone"
                            label="Телефон"
                            :maxlength="12"
                            :error="checkError('phone')"
                        ></input-box>
                    </div>
                    <div class="field">
                        <input-box
                            v-model="data['email']"
                            label="E-mail"
                            :error="checkError('email')"
                        ></input-box>
                    </div>
                </div>
            </div>
            <div v-if="currentTab === 'orders'">
                <div class="info">
                    заказы
                </div>
            </div>
        </div>
        <div class="foot">
            <div class="button-box" @click="save">Сохранить</div>
            <div class="button-box grey" @click="close">Закрыть</div>
        </div>
    </div>
</template>

<script>
import InputBox from "./../fields/InputBox";

export default {
    name: "ClientsEditor",
    components: {InputBox},
    props: {
        id: [Number, null],
        title: String,
        errors: Array,
        item: Object
    },
    methods: {
        save() {
            this.hasError = [];
            this.data['id'] = this.id;
            this.$emit('save', this.data)
        },
        checkError(key) {
            return !!this.hasError.find(error => error.key === key);
        },
        close() {
            this.$emit('close');
        },
        changeTab(tab) {
            this.currentTab = tab;
        }
    },
    watch: {
        item(value) {
            this.data = value;
        },
        errors(value) {
            this.hasError = value;
        }
    },
    computed: {
        tabs() {
            let tabs = [{
                key: 'info',
                label: 'Информация'
            }];
            if (this.id > 0) {
                tabs.push({
                    key: 'orders',
                    label: 'Заказы'
                })
            }
            return tabs;
        }
    },
    data() {
        return {
            data: {},
            hasError: [],
            currentTab: 'info',
        }
    }
}
</script>
